'use client';

import { LocalizedLink } from '@/components/Link';
import classNames from 'classnames';
import styles from './styles.module.css';
import { ButtonOverride } from '@/libs/api/arena-data';
import { buildButtonCSSOverrides } from '@/libs/utils/utils';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
export const Button = (props: {
  variation?: 'fill' | 'outline';
  className?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  overrides?: ButtonOverride[] | [];
  children?: React.ReactNode;
  focusable?: boolean;
  staticStyling?: boolean;
}) => {
  const {
    isDesktop
  } = useDeviceDetector();
  const isDesktopDetected = isDesktop();
  const classes = classNames(styles.button, props?.className, props?.variation === 'fill' ? undefined : styles['fill'], props?.staticStyling ? styles.static : undefined),
    overrideStyles = buildButtonCSSOverrides(isDesktopDetected, props?.overrides ?? []),
    attributes = {
      className: classes,
      style: overrideStyles,
      onClick: props.onClick,
      tabIndex: props?.focusable === false ? -1 : 0
    };
  const StyleGradientSupported = () => !overrideStyles?.['--normal-background'] ? null : <style dangerouslySetInnerHTML={{
    __html: `
            .${styles.button} {
                --normal-background: ${overrideStyles?.['--normal-background']};
        `
  }} />;
  if (props?.href) {
    return <LocalizedLink href={props?.href} target='_self' {...attributes as any}>
				<StyleGradientSupported />
				{props?.children}
			</LocalizedLink>;
  }
  return <button type={'button'} {...attributes as any} data-sentry-component="Button" data-sentry-source-file="index.tsx">
			<StyleGradientSupported data-sentry-element="StyleGradientSupported" data-sentry-source-file="index.tsx" />
			{props?.children}
		</button>;
};