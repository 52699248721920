import { ArenaDataState } from '@/libs/api/arena-data';
import { sanitizeSearch } from '@/features/sanitize/sanitizeSearch';
import { SearchResponse } from '@/root/types/search';
import Fuse from 'fuse.js';
import { DEFAULT_ROOT_CATEGORIES_SLUG } from '@/libs/utils/constants';

export const gamesWithCMSCategoriesDTO = async (arenaData: ArenaDataState) => {
	// @ts-ignore
	const gamefeed = arenaData._tempGameFeed;
	// @ts-ignore
	const categories = arenaData._tempCategories;

	const categoryImageMap = new Map(
		categories?.map((cat: any) => [cat.name?.toLowerCase().trim() ?? '', cat.image?.url ?? '']) ?? [],
	);

	return (gamefeed ?? []).map((game: any) => {
		return {
			...game,
			meta: {
				...(game.meta ?? {}),
				categories: (game.meta?.categories ?? []).map((category: any) => {
					const formattedName = category
						.split(' ')
						.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
						.join(' ');
					return {
						name: formattedName,
						image: categoryImageMap.get((category ?? '').toLowerCase().trim()) ?? '',
						slug: arenaData?.categoriesSlug ?? DEFAULT_ROOT_CATEGORIES_SLUG,
					};
				}),
			},
		};
	});
};

const searchOptions = {
	keys: ['name', 'meta.categories.name', 'meta.tags', 'meta.badges', 'meta.name'],
	threshold: 0.3,
	includeMatches: true,
};

export default async function search(arenaData: ArenaDataState, query?: string): Promise<SearchResponse> {
	if (!query) return { results: [], isMalicious: false };
	try {
		let searchResults: any[] = [];
		// Client-side sanitization of search query
		const { isMalicious, sanitizedQuery } = sanitizeSearch(query);
		if (isMalicious) return { results: [], isMalicious }; // if is malicious query - do not search, the state of malicious will be handled with notification div
		const gamefeed = await gamesWithCMSCategoriesDTO(arenaData);
		const fuseInstance = new Fuse(gamefeed, searchOptions);

		// Executing search for results
		if (sanitizedQuery !== '') {
			searchResults = fuseInstance.search(sanitizedQuery);
		}

		return { results: searchResults, isMalicious: false };
	} catch (e) {
		return {
			results: [],
			isMalicious: true,
			error: String(e),
		};
	}
}
